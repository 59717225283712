import { AdTemplate } from '@/components/AdTemplate';
import { Base } from '@/components/Base';
import { Consent } from '@/components/Consent';
import { PageMeta } from '@/components/PageMeta';
import { SchemaMarkup } from '@/components/SchemaMarkup';
import { Tracking, TrackingProps } from '@/components/Tracking';
import { Content, Meta } from '@/types/content';
import { isDeviceInvalid } from '@/utils/isDeviceInvalid';
import { CLOUDFLARE_TTL } from '@/utils/ttl';
import { withCacheControlHeaders } from '@/utils/withCacheControlHeaders';
import { withLockedContentHeaders } from '@/utils/withLockedContentHeaders';
import { withOcelotCacheTagsHeaders } from '@/utils/withOcelotCacheTagsHeaders';
import { withSerializationGuard } from '@/utils/withSerializationGuard';
import { withTestContentHeaders } from '@/utils/withTestContentHeaders';
import { InfinityScrollFront } from 'base/components/InfinityScrollFront';
import EmbedLogin from 'lib/AlltIdAuth/index';
import { getLabradorPage } from 'lib/labrador/getLabradorPage';
import { getLabradorPageProps } from 'lib/labrador/getLabradorPageProps';
import { handleCommonLabradorPageStatus } from 'lib/labrador/handleCommonLabradorPageStatus';
import { AdsMeta } from 'lib/labrador/types';
import { stripSlashes } from 'lib/labrador/utils';
import { renderPostTypeComponent } from 'modules/dynamic/components';
import { GetServerSideProps, NextPage } from 'next';

interface PageProps {
  pageData: Content;
  adsData: AdsMeta;
  device: string;
}

const Page: NextPage<PageProps> = ({ pageData, adsData }) => {
  const infinityScrollId = pageData.meta.infinityScrollID as string;
  const infinityScrollMode = pageData.meta.infinityScrollMode as 'front' | 'article';
  const category = pageData.data.category as string;
  const analyticsData = (pageData.data.analyticsData || {}) as TrackingProps;

  return (
    <Base
      content={
        <>
          <Consent />
          <PageMeta pageData={pageData} />
          <AdTemplate pageMeta={pageData?.meta as Meta} adsData={adsData} />
          <Tracking {...analyticsData} />
          <SchemaMarkup pageData={pageData} />
          <EmbedLogin />
          {renderPostTypeComponent(pageData)}
          <InfinityScrollFront
            pageData={pageData}
            sourceRoute={
              infinityScrollMode === 'front' && infinityScrollId
                ? ['_scroll', 'front', infinityScrollId].join('/')
                : ['_scroll', 'article', category].join('/')
            }
          />
          <div id="modal"></div>
        </>
      }
    />
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { device, params = [] } = context.params as { device: string; params?: string[] };

  if (isDeviceInvalid(device)) {
    return { notFound: true };
  }

  const routeKey = params;
  const data = await getLabradorPage(device, routeKey, context.req.url);

  const commonLabradorResponse = handleCommonLabradorPageStatus(data);
  if (commonLabradorResponse) return commonLabradorResponse;

  const publishedUrl = data?.pageData?.data?.publishedUrl;
  if (stripSlashes(publishedUrl) !== routeKey.join('/')) {
    return { redirect: { destination: publishedUrl, permanent: true } };
  }

  withOcelotCacheTagsHeaders(context, data);
  withLockedContentHeaders(context, data);
  withTestContentHeaders(context, data);
  withCacheControlHeaders(context.res, CLOUDFLARE_TTL);

  return withSerializationGuard({
    props: getLabradorPageProps(data),
  });
};

export default Page;
